<template>
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M5 5C4.53976 5 4.16667 5.3731 4.16667 5.83334V15.8333C4.16667 16.2936 4.53976 16.6667 5 16.6667H15C15.4602 16.6667 15.8333 16.2936 15.8333 15.8333V5.83334C15.8333 5.3731 15.4602 5 15 5H5ZM2.5 5.83334C2.5 4.45262 3.61929 3.33334 5 3.33334H15C16.3807 3.33334 17.5 4.45262 17.5 5.83334V15.8333C17.5 17.214 16.3807 18.3333 15 18.3333H5C3.61929 18.3333 2.5 17.214 2.5 15.8333V5.83334Z" fill="black"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M13.3333 1.66666C13.7936 1.66666 14.1667 2.03976 14.1667 2.5V5.83333C14.1667 6.29357 13.7936 6.66666 13.3333 6.66666C12.8731 6.66666 12.5 6.29357 12.5 5.83333V2.5C12.5 2.03976 12.8731 1.66666 13.3333 1.66666Z" fill="black"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M6.66671 1.66666C7.12694 1.66666 7.50004 2.03976 7.50004 2.5V5.83333C7.50004 6.29357 7.12694 6.66666 6.66671 6.66666C6.20647 6.66666 5.83337 6.29357 5.83337 5.83333V2.5C5.83337 2.03976 6.20647 1.66666 6.66671 1.66666Z" fill="black"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M2.5 9.16666C2.5 8.70642 2.8731 8.33333 3.33333 8.33333H16.6667C17.1269 8.33333 17.5 8.70642 17.5 9.16666C17.5 9.6269 17.1269 10 16.6667 10H3.33333C2.8731 10 2.5 9.6269 2.5 9.16666Z" fill="black"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M5.83337 12.5C5.83337 12.0398 6.20647 11.6667 6.66671 11.6667H8.33337C8.79361 11.6667 9.16671 12.0398 9.16671 12.5V14.1667C9.16671 14.6269 8.79361 15 8.33337 15H6.66671C6.20647 15 5.83337 14.6269 5.83337 14.1667V12.5Z" fill="black"/>
    </svg>
</template>

<script>
export default {
    name: 'IconCalendar'
}
</script>